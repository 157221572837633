import { useVideoVoiceSetting } from '@/src/data/agentDashboard/useVideoVoiceSetting';
import { useMemo } from 'react';
import type { IVideo } from '@/types/video';
import type { CustomVideoPlayerProps } from '@/src/shared/components/CustomVideoPlayer/types';
import { isEmptyString } from '@/src/shared/utils/string';

interface Options {
  enabled?: boolean;
  initialCustomVideo?: CustomVideoPlayerProps['options']['customAudio'];
}

export function useRecordedVoice(video: IVideo, options?: Options) {
  const { data: videoVoiceSettings } = useVideoVoiceSetting(
    {
      videoId: video?.id,
    },
    {
      enabled: options?.enabled && video?.id != null,
    }
  );

  const voiceOverUrl = useMemo(() => {
    if (!options?.enabled) {
      return null;
    }
    return videoVoiceSettings?.custom_audios?.find((item) => {
      return item.type === videoVoiceSettings?.voice_over_type;
    })?.url;
  }, [
    options?.enabled,
    videoVoiceSettings?.custom_audios,
    videoVoiceSettings?.voice_over_type,
  ]);

  const voiceOverOptions = useMemo<
    CustomVideoPlayerProps['options']['customAudio']
  >(() => {
    if (!options?.enabled || !isEmptyString(options?.initialCustomVideo?.url)) {
      return {
        hasVoiceOver: options?.initialCustomVideo?.hasVoiceOver ?? null,
        hasBackgroundMusic:
          options?.initialCustomVideo?.hasBackgroundMusic ?? null,
        url: options?.initialCustomVideo?.url ?? null,
      };
    }
    return {
      hasVoiceOver: videoVoiceSettings?.has_voice_over,
      hasBackgroundMusic: videoVoiceSettings?.has_background_music,
      url: voiceOverUrl,
    };
  }, [
    options?.enabled,
    options?.initialCustomVideo,
    videoVoiceSettings?.has_background_music,
    videoVoiceSettings?.has_voice_over,
    voiceOverUrl,
  ]);
  return { voiceOverOptions };
}
