import styles from './index.module.scss';
import React, {
  createRef,
  KeyboardEvent,
  MutableRefObject,
  PureComponent,
} from 'react';
import AutoComplete from '@/src/shared/components/Antd/AutoComplete';
import { callPostApi } from '@/src/core/utils/api';
import type { AxiosResponse } from 'axios';
import type { RefSelectProps } from 'antd/lib/select';
import { clsx } from 'clsx';

export type ICities = {
  name?: string;
  region?: number;
  region_id?: number;
  key?: number;
  country_code?: string;
};

export interface FacebookAddressAutocompleteProps {
  onSelect: (
    cityName: string,
    regionName: string,
    key: number,
    country_code: string
  ) => void;
  placeholder: string;
  defaultValue: string;
  autoFocus?: boolean;
  prefix?: React.ReactNode;
  className?: string;
  dropdownClassName?: string;
  onFocus?: () => void;
  onBlur?: (value?: string) => void;
  disabled?: boolean;
  classes?: {
    root?: string;
  };
  setLocation?: MutableRefObject<
    (city: string, region: string, key: number) => void
  >;
  handleSubmit?: () => void;
  onChange?: (value: string) => void;
}

export class FacebookAddressAutocomplete extends PureComponent<FacebookAddressAutocompleteProps> {
  autoCompleteRef = createRef<RefSelectProps>();
  timer = null;

  fetchCities = async (city) => {
    const response: AxiosResponse<{
      data: ICities[];
    }> = await callPostApi('/v1/location/facebook-targeting', {
      city: city,
    });
    return response.data.data;
  };

  state = {
    value: this.props.defaultValue,
    options: [],
    cities: [],
    selected: this.props.defaultValue,
    autoFocus: false,
    pending: false,
    open: false,
  };

  componentWillUnmount(): void {
    clearTimeout(this.timer);
  }

  componentDidMount = () => {
    if (this.props.setLocation) {
      this.props.setLocation.current = (
        city: string,
        region: string,
        key: number
      ) => {
        this.setState({ value: city });
        if (city && region && key) {
          this.onSelectCity(null, {
            city_name: city,
            region_name: region,
            key: key,
          });
        }
      };
    }
  };

  onSelectCity = (value, option) => {
    this.setState({
      value: option.city_name + ', ' + option.region_name,
      selected: option.city_name + ', ' + option.region_name,
      open: false,
    });
    this.props.onSelect(
      option.city_name,
      option.region_name,
      option.key,
      option.country_code
    );
  };

  onChange = (value, o) => {
    clearTimeout(this.timer); // Clear timeout when user starts to type.

    this.setState({ value, open: true });
    this.props.onChange?.(value);

    /**
     * Create a 600ms timeout for starting to search the city.
     */
    this.timer = setTimeout(() => {
      this.onSearchCity(value);
    }, 600);
  };

  shouldOpen = () => {
    return this.state.value != this.props.defaultValue;
  };

  componentDidUpdate() {
    if (this.props.autoFocus) {
      this.autoCompleteRef.current.focus();
    }
  }

  onSearchCity = (searchText) => {
    (async () => {
      try {
        this.setState({ pending: true, options: [] });
        const cities = await this.fetchCities(searchText);
        const options = cities.map((city) => {
          return {
            label: `${city.name}, ${city.region}`,
            value: `${city.name}, ${city.region}`,
            city_name: city.name,
            region_name: city.region,
            key: city.key,
            country_code: city.country_code,
          };
        });
        this.setState({ options, pending: false });
      } catch (e) {
        console.error(e);
      }
    })();
  };

  /**
   * It triggers by pressing the enter key
   * @param {KeyboardEvent<HTMLInputElement>} e
   */
  onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      this.props.handleSubmit?.();
    }
  };

  render() {
    return (
      <div className={clsx(styles.container, this.props.classes?.root)}>
        <AutoComplete
          disabled={this.props.disabled}
          className={this.props.className}
          ref={this.autoCompleteRef}
          autoFocus={this.state.autoFocus}
          onBlur={() => {
            this.setState({ value: this.state.selected });
            if (this.props.onBlur) this.props.onBlur(this.state.selected);
          }}
          onFocus={this.props.onFocus}
          open={this.state.open && this.shouldOpen()}
          options={this.state.options}
          onSelect={this.onSelectCity}
          defaultValue={this.props.defaultValue}
          value={this.state.value}
          onChange={this.onChange}
          size="large"
          placeholder={this.props.placeholder}
          suffixIcon={this.props.prefix ?? null}
          onKeyDown={this.onKeyDown}
          notFoundContent={
            this.state.pending
              ? 'Loading...'
              : this.state.value == null || this.state.value === ''
              ? this.props.placeholder
              : 'Location not found'
          }
          popupClassName={this.props.dropdownClassName}
        />
      </div>
    );
  }
}
