import type { ReactNode } from 'react';
import { clsx } from 'clsx';
import styles from './Section.module.scss';

interface Props {
  className?: string;
  children: ReactNode;
  classes?: {
    wrapper?: string;
  };
}

export function Section({ children, className, classes }: Props) {
  return (
    <section className={clsx(styles.container, className)}>
      <div className={clsx(styles.wrapper, classes?.wrapper)}>{children}</div>
    </section>
  );
}
