import type { AxiosResponse } from 'axios';
import { callGetApi } from '@/src/core/utils/api';
import { VideoVoiceSettingResult, Params } from './types';

export const fetchVideoVoiceSetting = async (
  params: Params
): Promise<VideoVoiceSettingResult> => {
  const response: AxiosResponse<{
    status: string;
    data: VideoVoiceSettingResult;
    errors: string[];
  }> = await callGetApi(
    `/v1/agent-dashboard/video/${params.videoId}/voice-setting`
  );
  const { data } = response ?? {};
  if (data?.status === 'success') {
    return data?.data;
  } else {
    throw new Error('your request encountered an error');
  }
};
