import { type ReactNode, createContext, useContext, useState } from 'react';

interface HomeContextType {
  isRegistrationPopupOpen: boolean;
  openRegistrationPopup: () => void;
  closeRegistrationPopup: () => void;
}

const HomeContext = createContext<HomeContextType>({
  isRegistrationPopupOpen: false,
  openRegistrationPopup: () => null,
  closeRegistrationPopup: () => null,
});

interface Props {
  children: ReactNode;
}

export function HomeProvider({ children }: Props) {
  const [isRegistrationPopupOpen, setIsRegistrationPopupOpen] = useState(false);

  const openRegistrationPopup = () => {
    setIsRegistrationPopupOpen(true);
  };

  const closeRegistrationPopup = () => {
    setIsRegistrationPopupOpen(false);
  };

  return (
    <HomeContext.Provider
      value={{
        isRegistrationPopupOpen,
        openRegistrationPopup,
        closeRegistrationPopup,
      }}
    >
      {children}
    </HomeContext.Provider>
  );
}

export function useHome() {
  const context = useContext(HomeContext);
  if (context === undefined) {
    throw new Error('useHome must be used within a HomeContext');
  }
  return context;
}
