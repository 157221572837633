import config from '@/config';
import type { IUser } from '@/types/user';

export const defaultUser: Partial<IUser> = {
  id: 5727,
  name: 'Your Name',
  name2: 'Your Brokerage Name',
  phone: '+1 (555)-555-1234',
  overlay1: config.websiteBaseUrl + '/agent/overlay/style2/5727',
  overlay2: config.websiteBaseUrl + '/agent/overlay/b_style2/5727',
  mobile_video_branding: {
    overlay1: config.websiteBaseUrl + '/agent/overlay/style2/5727',
  },
};

export const defaultUserWithPremiumOverlay: Partial<IUser> = {
  id: 5727,
  overlay1: config.websiteBaseUrl + '/agent/overlay/style2/5727',
  overlay2: config.websiteBaseUrl + '/agent/overlay/b_style2/5727',
  mobile_video_branding: {
    overlay1: config.websiteBaseUrl + '/agent/overlay/style2/5727',
  },
  premium_intro: {
    structure: {
      id: 9,
    },
    template: {
      video_path:
        'https://dofimomuk6s4.cloudfront.net/p_branding/templates/1705562545.mp4',
    },
    elements: {
      text: {
        color: '#36454F',
        left: 190,
        top: 113,
        width: 700,
        height: 300,
        font: 'MONTSERRAT_EXTRABOLD',
        'font-size': '60',
        start: '0.5',
        align: 'Center',
      },
      user_name: {
        color: '#FFFFFF',
        left: 240,
        top: 747,
        width: 600,
        height: 127,
        font: 'MONTSERRAT_EXTRABOLD',
        'font-size': '50',
        start: '0.5',
        align: 'Center',
      },
      profile_image: {
        left: 365,
        top: 396,
        width: 350,
        height: 350,
        start: '0.5',
        type: 'circle',
      },
      phone: {
        color: '#36454F',
        left: 320,
        top: 900,
        width: 400,
        height: 35,
        font: 'MONTSERRAT_SEMIBOLD',
        'font-size': '38',
        start: '0.5',
        align: 'Center',
      },
      brokerage: {
        color: '#FFFFFF',
        left: 190,
        top: 977,
        width: 700,
        height: 63,
        font: 'MONTSERRAT_SEMIBOLD',
        'font-size': '38',
        start: '0.5',
        align: 'Center',
      },
      logo: {
        left: '',
        top: '',
        width: '',
        height: '',
        start: '0',
        type: 'circle',
      },
    },
  },
};
