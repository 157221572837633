import React from 'react';
import styles from './Video.module.scss';
import type { IIPLocationDetails } from '@/types/misc';
import type { IVideo } from '@/types/video';
import { defaultUser } from '@/src/shared/constants/defaultUser';
import { Typography } from '@/src/shared/components/Typography';
import Image from '@/src/shared/components/Image';
import { clsx } from 'clsx';
import {
  isMobileOptimizedVideo,
  isReelsVideo,
  isRegularVideo,
  isSquareVideo,
} from '@/src/core/utils/videos';
import { CustomVideoPlayer } from '@/src/shared/components/CustomVideoPlayer';

interface Props {
  ipLocationDetails?: IIPLocationDetails;
  video: IVideo;
}

export function Video({ ipLocationDetails, video }: Props) {
  return (
    <div
      className={clsx(styles.playerWrapper, {
        [styles.regular]: isRegularVideo(video),
        [styles.square]: isSquareVideo(video),
        [styles.reels]: isReelsVideo(video),
        [styles.mobileOptimized]: isMobileOptimizedVideo(video),
      })}
    >
      <div className={styles.content}>
        <div className={styles.videoWrapper}>
          {!isReelsVideo(video) && (
            <div className={styles.topBanner}>
              <Image
                src="/next-public/images/home/v6/hero/video-frame/top_banner.webp"
                alt="top-banner"
                fill
              />
            </div>
          )}
          <CustomVideoPlayer user={defaultUser} video={video} />
          <div className={styles.bottomBanner}>
            <Image
              src="/next-public/images/home/v6/hero/video-frame/bottom_banner-v2.webp"
              alt="bottom-banner"
              fill
            />
          </div>
        </div>

        <Typography
          className={styles.videoTitle}
          variant="text"
          fontWeight="medium"
          size="md"
        >
          {ipLocationDetails?.city} Market Update
        </Typography>
      </div>
      <div className={styles.bg} />
    </div>
  );
}
