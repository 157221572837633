import { useQuery } from '@tanstack/react-query';
import { getUseVideoVoiceSettingKey } from './queryKey';
import { fetchVideoVoiceSetting } from './queryFunction';
import type { Options, Params } from './types';
import { reactQueryConfigs } from '@/src/core/providers/ReactQueryProvider/configs';

export function useVideoVoiceSetting(params: Params, options?: Options) {
  return useQuery(
    getUseVideoVoiceSettingKey(params),
    () => fetchVideoVoiceSetting(params),
    {
      ...options,
      staleTime: reactQueryConfigs.staleTime,
      cacheTime: reactQueryConfigs.cacheTime,
    }
  );
}
