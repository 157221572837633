import React from 'react';
import type { GeoLocationResult } from '@/src/containers/Landing/utils/geoVideo/types';
import type { IIPLocationDetails } from '@/types/misc';
import type { IVideo } from '@/types/video';
import { LocationInput } from './LocationInput';
import { Video } from './Video';
import { Section } from '../Section';
import { useIsMobile } from '@/src/shared/hooks/utils/useIsMobile';
import styles from './HeroSection.module.scss';

interface Props {
  regions: GeoLocationResult['regions'];
  detectedRegion: GeoLocationResult['detectedRegion'];
  defaultRegionName: GeoLocationResult['default_region_name'];
  ipLocationDetails?: IIPLocationDetails;
  video: IVideo;
}

export function HeroSection({
  regions,
  detectedRegion,
  defaultRegionName,
  ipLocationDetails,
  video,
}: Props) {
  const isMobile = useIsMobile();
  return (
    <Section className={styles.section}>
      <div className={styles.container}>
        <div className={styles.firstSectionWrapper}>
          <h1 className={styles.title}>
            Hyper-Local Real Estate Video Marketing
          </h1>
          <p className={styles.subTitle}>
            We auto-post daily content tailored for you on your social media
          </p>
          {isMobile && (
            <Video video={video} ipLocationDetails={ipLocationDetails} />
          )}
          <LocationInput
            video={video}
            regions={regions}
            defaultRegionName={defaultRegionName}
            detectedRegion={detectedRegion}
            ipLocationDetails={ipLocationDetails}
          />
        </div>
        {!isMobile && (
          <Video video={video} ipLocationDetails={ipLocationDetails} />
        )}
      </div>
    </Section>
  );
}
