import type { IVideo } from '@/types/video';

export function isSquareVideo(video: IVideo) {
  return (
    video?.video_file_type === 'square' ||
    video?.video_file_type === 'square_short'
  );
}

export function isMobileOptimizedVideo(video: IVideo) {
  return video?.video_file_type === 'mobile_optimized';
}

export function isRegularVideo(video: IVideo) {
  return video?.video_file_type === 'regular';
}

export function isReelsVideo(video: IVideo) {
  return video?.video_file_type === 'reels';
}
