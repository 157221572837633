import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import HomeHeader from '@/src/shared/components/HomeHeader';
import { wrapper } from '@/redux/store';
import { withAuthentication } from '@/src/core/utils/authentication';
import { selectIsAuthenticated } from '@/redux/user/selectors';
import {
  getMustDisplayHomePageRoute,
  shouldUseHomePage,
} from '@/src/shared/utils/abTesting/homepage';
import { getDynamicInfoForVideoLandingPage } from '@/features/geovideo';
import { withReactDetectDevice } from '@/src/core/utils/ssr/withDetectDevice';
import { GeoLocationResult } from '@/src/containers/Landing/utils/geoVideo/types';
import PopupManager from '@/src/core/components/PopupManager';
import { getIp } from '@/src/shared/utils/ssr/getIp';
import Footer from '@/src/shared/components/Footer';
import { fetchUserLocation } from '@/src/data/landing/useUserLocation';
import { HeroSection } from '@/src/containers/HomeV6/components/HeroSection';
import { redirectTo } from '@/src/shared/utils/ssr/redirectTo';
import { useEventProvider } from '@/helpers/event-context';
import { Pages } from '@/types/pages';
import { HomeProvider } from '@/src/containers/HomeV6/components/HomeProvider';
import { useIsMobile } from '@/src/shared/hooks/utils/useIsMobile';
import GoogleOneTap from '@/src/core/components/GoogleOneTapLogin/GoogleOneTapLogin';

const SolutionsSection = dynamic(
  () => import('@/src/containers/HomeV6/components/SolutionsSection')
);
const AsSeenSection = dynamic(
  () => import('@/src/containers/HomeV6/components/AsSeenSection')
);
const ChooseRoomvuSection = dynamic(
  () => import('@/src/containers/HomeV6/components/ChooseRoomvuSection')
);
const HowToWinSection = dynamic(
  () => import('@/src/containers/HomeV6/components/HowToWinSection')
);
const FeaturesSection = dynamic(
  () => import('@/src/containers/HomeV6/components/FeaturesSection')
);
const WhatAgentsSaySection = dynamic(
  () => import('@/src/containers/HomeV6/components/WhatAgentsSaySection')
);
const JoinSection = dynamic(
  () => import('@/src/containers/HomeV6/components/JoinSection')
);
const LeadersSection = dynamic(
  () => import('@/src/containers/HomeV6/components/LeadersSection')
);
const AgentSection = dynamic(
  () => import('@/src/containers/HomeV6/components/AgentSection')
);
const InView = dynamic(() => import('@/src/shared/components/InView'));

const source: Pages = 'home_page2';

type Props = GeoLocationResult;

function HomePage({
  usersCount,
  video,
  regions,
  detectedRegion,
  default_region_name,
  ip_location_details,
}: Props) {
  const [isSecondFoldVisible, setIsSecondFoldVisible] = useState(false);
  const { sendEvent } = useEventProvider();
  const isMobile = useIsMobile();

  return (
    <>
      <HomeHeader
        bgColor="#fff"
        onRequestDemoButtonClick={() => {
          sendEvent('request_demo_clicked', null, { section: 'navbar' });
        }}
        onGetStartedButtonClick={() => {
          sendEvent('get_started_free_clicked', null, { section: 'navbar' });
        }}
        onLoginClick={() => {
          sendEvent('login_clicked', null, { section: 'navbar' });
        }}
      />
      <HomeProvider>
        <HeroSection
          regions={regions}
          defaultRegionName={default_region_name}
          detectedRegion={detectedRegion}
          ipLocationDetails={ip_location_details}
          video={video}
        />
        {!isMobile && <SolutionsSection />}
        <AsSeenSection />

        {!isSecondFoldVisible && (
          <InView
            rootMargin="150px"
            onVisible={() => {
              setIsSecondFoldVisible(true);
            }}
          />
        )}

        {isSecondFoldVisible && (
          <>
            <ChooseRoomvuSection />
            <LeadersSection />
            <HowToWinSection />
            <AgentSection />
            <FeaturesSection />
            {!isMobile && <WhatAgentsSaySection />}
            <JoinSection
              numberOfUsers={usersCount}
              regions={regions}
              defaultRegionName={default_region_name}
              detectedRegion={detectedRegion}
              ipLocationDetails={ip_location_details}
              video={video}
            />
            <Footer />
          </>
        )}
      </HomeProvider>

      <PopupManager />
      <GoogleOneTap
        detectedRegion={detectedRegion}
        video={video}
        source={source}
      />
      <style jsx global>{`
        .intercom-lightweight-app-launcher,
        .intercom-app :nth-child(2) {
          @media (max-width: 768px) {
            bottom: 90px !important;
            right: 4px !important;
          }
        }
      `}</style>
    </>
  );
}

export const getServerSideProps = wrapper.getServerSideProps((store) =>
  withAuthentication(
    store,
    withReactDetectDevice(store, async ({ req, res, query }) => {
      const state = store.getState();
      const isAuthenticated = selectIsAuthenticated(state);

      const homeVersion = shouldUseHomePage({
        req,
        res,
        authenticated: isAuthenticated,
      });
      if (homeVersion !== 'v1' && !query.force) {
        return redirectTo(getMustDisplayHomePageRoute(homeVersion));
      }

      const [dynamicInfo] = await Promise.all([
        getDynamicInfoForVideoLandingPage({
          ...query,
          ip: getIp({ req, query }),
        }),
        fetchUserLocation({
          ip: getIp({ query, req }),
        }),
      ]);

      return {
        props: {
          usersCount: dynamicInfo?.usersCount,
          video: dynamicInfo?.video,
          regions: dynamicInfo?.regions,
          detectedRegion: dynamicInfo?.detectedRegion,
          default_region_name: dynamicInfo?.default_region_name,
          ip_location_details: dynamicInfo?.ip_location_details,
          default_region_title: dynamicInfo?.default_region_title,
        },
      };
    }),
    { dontRedirectToLoginPage: true }
  )
);

HomePage.pageName = source;
HomePage.withIntercomChatBot = true;
export default HomePage;
